<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs6 no-padding">
						<h1>Clientes</h1>
					</div>
					<div class="flex xs6 no-padding text-right btn-group-custom">
						<div class="va-row header-button">
							<button class="btn btn-change" v-on:click="addClient()">
								ADICIONAR CLIENTE
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>
		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="tab">
						<button :class="{ active: activePage === 1 }" @click="changePage(1)">
							Clientes ativos
						</button>
						<button :class="{ active: activePage === 0 }" @click="changePage(0)">
							Clientes inativos
						</button>
					</div>
				</div>
			</div>


			<div class="my-data-table va-row" id="cards">
				<div class="flex xs12 no-padding fiscal-notes">
					<div class="body-spacing body-contains">
						<div class="sets-list table-list-simple va-row" v-if="clients.length > 0">
							<div class="va-row header no-padding">
								<div class="flex item main xs4">LOGO</div>
								<div class="flex item  xs4">CLIENTE</div>
								<div class="flex item xs4">MAIS INFORMAÇÕES</div>
							</div>
							<div class="flex md12 no-padding simple-list-body">
								<div class="flex md12 no-padding line-item" v-for="item in clients" :key="item.id">
									<div class="va-row lines">
										<div class="flex item xs4">
											<div class="client-logo">
												<img :src="item.logo">
											</div>
										</div>
										<div class="flex item xs4">
											<span class="client_name">{{ item.name }}</span>
										</div>
										<div class="flex item xs4" v-if="item.active == true">
											<div class="va-row">
												<div class="flex xs6">
													<button :class="[
														'btn-client',
														'btn',
														'right',
														item.isOnlySpecialContract == false ? 'disabled' : ''
													]" :disabled="item.isOnlySpecialContract == false" v-on:click="editClient(item)">
														EDITAR
													</button>
												</div>
												<div class="flex xs6">
													<button class="btn right btn-client"
														v-on:click="deleteClient(item.id)">
														INATIVAR
													</button>
												</div>
												<div class="flex xs12 client-special-description-container">
													<span class="client-special-description"
														v-if="item.isOnlySpecialContract == false">
														Somente clientes contrato especial podem ser editados
													</span>
												</div>
											</div>


										</div>
										<div class="flex item xs4" v-if="item.active == false">
											<button class="btn right" v-on:click="restoreClient(item.id)">
												REATIVAR
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="showNotification" :class="[
			isErrorNotification
				? 'notification-error'
				: 'notification-success',
			'notification',
		]">
			{{ notificationErrorMessage }}
		</div>

		<vuestic-modal id="clientModal" ref="clientModal">
			<div slot="title">{{ clientEditing == null ? "Adicionar Cliente" : "Editar Cliente" }}</div>
			<client-edit :clientId="clientEditing" @closeModal="closeModal"></client-edit>
		</vuestic-modal>
	</div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";

const axios = require("axios");

export default {
	name: "clients",
	directives: { mask },
	computed: {
		...mapGetters({
			managerCredential: "managerCredential"
		})
	},
	mounted() {
		const self = this;
		self.getClients();
	},
	data() {
		return {
			clients: [],
			clientEditing: null,
			activePage: 1,
			showNotification: false,
			notificationErrorMessage: "",
			isErrorNotification: true
		};
	},
	methods: {
		restoreClient(clientId) {
			if (confirm("Tem certeza que deseja reativar esse cliente?") == true) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				axios
					.get(process.env.VUE_APP_ROOT_API + "/clients/restore/" + clientId, config)
					.then((response) => {
						self.clients = self.clients.filter(item => item.id !== clientId);
						self.isErrorNotification = false;
						self.notificationErrorMessage = "Cliente reativado com sucesso.";
						self.showNotificationError();
					})
					.catch((e) => {
						self.isErrorNotification = true;
						self.notificationErrorMessage = e.response.data.message;
						self.showNotificationError();
					});
			}
		},
		deleteClient(clientId) {
			if (confirm("Tem certeza que deseja inativar esse cliente?") == true) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				axios
					.delete(process.env.VUE_APP_ROOT_API + "/clients/" + clientId, config)
					.then((response) => {
						self.clients = self.clients.filter(item => item.id !== clientId);
						self.updateUserClientsList(self.clients);
						console.log(self.clients);
						self.isErrorNotification = false;
						self.notificationErrorMessage = "Cliente inativado com sucesso.";
						self.showNotificationError();
					})
					.catch((e) => {
						self.isErrorNotification = true;
						self.notificationErrorMessage = e.response.data.message;
						self.showNotificationError();
					});
			}
		},
		editClient(client) {
			const self = this;
			if (client.isOnlySpecialContract) {
				self.clientEditing = client.id;
				self.$refs.clientModal.open();
			}
		},
		addClient() {
			const self = this;
			self.clientEditing = null;
			self.$refs.clientModal.open();
		},
		closeModal() {
			const self = this;
			self.clientEditing = null;
			self.$refs.clientModal.close();
		},
		changePage(page) {
			this.activePage = page;
			this.getClients();
		},
		getClients() {
			const self = this;

			let config = {
				headers: {
					Authorization: "Bearer " + this.managerCredential.token,
				},
			};

			const endpoint = self.activePage == 1 ? '/clients_special_contract' : '/clients/trashed';

			axios
				.get(process.env.VUE_APP_ROOT_API + endpoint, config)
				.then((response) => {
					self.clients = response.data.data.clients;
				})
				.catch((e) => {
					this.errors.push(e);
				});
		},
		showNotificationError() {
			const self = this;
			self.showNotification = true;
			setTimeout(() => {
				self.showNotification = false;
				self.notificationErrorMessage = "";
			}, 5000);
		},
		updateUserClientsList(clients) {
            const self = this;
            self.managerCredential.available_clients = clients;
            self.$store.commit("SAVE_MANAGER_CREDENTIALS", self.managerCredential);
            self.$router.go(0);
        }
	}
};

</script>

<style lang="scss" scoped>
.client-logo {
	width: 120px;
	/* Defina o tamanho da div */
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	/* Evita que partes da imagem fiquem fora da div */
}

.client-logo img {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
	/* Mantém a proporção dentro da div */
}

.sets-list {
	label {
		font-size: 12px;
	}

	::v-deep .select {
		font-size: 14px !important;
	}
}

.table-list-simple {
	.item {
		&.main {
			width: 40%;
		}

		&.paid {
			color: #00cf72;
		}

		.link {
			color: #4965bc;
			text-decoration: underline;
			cursor: pointer;
			font-size: 14px;
		}

		.product-title {
			text-transform: capitalize;
		}
	}

	.lines {
		border-bottom: 1px solid #dfdae4 !important;

		.item {
			border-right: 1px solid #dfdae4;
			padding: 24px;

			&:last-child {
				border-right: none;
			}
		}
	}
}

.simple-list-body .line-item:last-child {
	.lines {
		border-bottom: none !important;
	}
}

.my-data-table {
	h4 {
		margin-top: 50px;
		margin-bottom: 20px;
	}
}

.fiscal-notes {
	border-radius: 0px 0px 16px 16px;
}

.body-spacing {
	padding: 30px 30px;

	@media (max-width: 990px) {
		padding: 25px 15px 15px 15px;
	}
}

.body-contains {
	padding: 32px !important;
}

.client_name {
	font-size: 18px;
	font-weight: bolder;
}

.btn-change-edit {
	border: none;
	color: #4965bc;
	background: transparent;
	font-size: 20px;
	padding: 0px 8px;

	&:disabled {
		color: #dfdae4;
	}

	svg {
		path {
			fill: #4965bc;
		}
	}

	&:disabled {
		svg {
			path {
				fill: #dfdae4;
			}
		}
	}
}

.disabled {
	background-color: #cfd7ed !important;
}

.btn-client {
	width: 100%;
}

.client-special-description {
	font-size: 13px;
	font-weight: bolder;
	color: #ff3f3f;
}

.client-special-description-container {
	text-align: center;
}

.btn-group-custom {
	align-items: center;
	display: flex;
}

.header-button {
	justify-content: right;
}

.notification {
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	background-color: #df463e;
	color: white;
	padding: 10px 20px;
	border-radius: 5px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	font-size: 14px;
	animation: fade-in-out 2s ease-in-out;
	z-index: 1000;
}

.notification-error {
	background-color: #df463e;
}

.notification-success {
    background-color: #4caf50;
  }
</style>