var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-account"},[_c('div',{staticClass:"header-menu va-row"},[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex container-ddh-menu xs12 with-bottom va-row"},[_vm._m(0),_c('div',{staticClass:"flex xs6 no-padding text-right btn-group-custom"},[_c('div',{staticClass:"va-row header-button"},[_c('button',{staticClass:"btn btn-change",on:{"click":function($event){return _vm.addClient()}}},[_vm._v(" ADICIONAR CLIENTE ")])])])])]),_c('div',{staticClass:"clearfix"})]),_c('div',{staticClass:"container-dhh va-row"},[_c('div',{staticClass:"flex md12 xs12 no-padding"},[_c('div',{staticClass:"ui-grid"},[_c('div',{staticClass:"tab"},[_c('button',{class:{ active: _vm.activePage === 1 },on:{"click":function($event){return _vm.changePage(1)}}},[_vm._v(" Clientes ativos ")]),_c('button',{class:{ active: _vm.activePage === 0 },on:{"click":function($event){return _vm.changePage(0)}}},[_vm._v(" Clientes inativos ")])])])]),_c('div',{staticClass:"my-data-table va-row",attrs:{"id":"cards"}},[_c('div',{staticClass:"flex xs12 no-padding fiscal-notes"},[_c('div',{staticClass:"body-spacing body-contains"},[(_vm.clients.length > 0)?_c('div',{staticClass:"sets-list table-list-simple va-row"},[_vm._m(1),_c('div',{staticClass:"flex md12 no-padding simple-list-body"},_vm._l((_vm.clients),function(item){return _c('div',{key:item.id,staticClass:"flex md12 no-padding line-item"},[_c('div',{staticClass:"va-row lines"},[_c('div',{staticClass:"flex item xs4"},[_c('div',{staticClass:"client-logo"},[_c('img',{attrs:{"src":item.logo}})])]),_c('div',{staticClass:"flex item xs4"},[_c('span',{staticClass:"client_name"},[_vm._v(_vm._s(item.name))])]),(item.active == true)?_c('div',{staticClass:"flex item xs4"},[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex xs6"},[_c('button',{class:[
													'btn-client',
													'btn',
													'right',
													item.isOnlySpecialContract == false ? 'disabled' : ''
												],attrs:{"disabled":item.isOnlySpecialContract == false},on:{"click":function($event){return _vm.editClient(item)}}},[_vm._v(" EDITAR ")])]),_c('div',{staticClass:"flex xs6"},[_c('button',{staticClass:"btn right btn-client",on:{"click":function($event){return _vm.deleteClient(item.id)}}},[_vm._v(" INATIVAR ")])]),_c('div',{staticClass:"flex xs12 client-special-description-container"},[(item.isOnlySpecialContract == false)?_c('span',{staticClass:"client-special-description"},[_vm._v(" Somente clientes contrato especial podem ser editados ")]):_vm._e()])])]):_vm._e(),(item.active == false)?_c('div',{staticClass:"flex item xs4"},[_c('button',{staticClass:"btn right",on:{"click":function($event){return _vm.restoreClient(item.id)}}},[_vm._v(" REATIVAR ")])]):_vm._e()])])}),0)]):_vm._e()])])])]),(_vm.showNotification)?_c('div',{class:[
		_vm.isErrorNotification
			? 'notification-error'
			: 'notification-success',
		'notification',
	]},[_vm._v(" "+_vm._s(_vm.notificationErrorMessage)+" ")]):_vm._e(),_c('vuestic-modal',{ref:"clientModal",attrs:{"id":"clientModal"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(_vm.clientEditing == null ? "Adicionar Cliente" : "Editar Cliente"))]),_c('client-edit',{attrs:{"clientId":_vm.clientEditing},on:{"closeModal":_vm.closeModal}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs6 no-padding"},[_c('h1',[_vm._v("Clientes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"va-row header no-padding"},[_c('div',{staticClass:"flex item main xs4"},[_vm._v("LOGO")]),_c('div',{staticClass:"flex item xs4"},[_vm._v("CLIENTE")]),_c('div',{staticClass:"flex item xs4"},[_vm._v("MAIS INFORMAÇÕES")])])
}]

export { render, staticRenderFns }